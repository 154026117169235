import React from 'react';
import clsx from 'clsx';

import * as styles from './PageTitle.module.css';

export const PageTitle = (props) => {
  const { children, id, className } = props;
  return (
    <div id={id} className={clsx(className, styles.container)}>
      <h2>{children}</h2>
    </div>
  );
};

PageTitle.propTypes = {};
PageTitle.defaultProps = {};
