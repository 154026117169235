import React from 'react';

import * as styles from './LimitContentWidth.module.css';

export const LimitContentWidth = (props) => {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
};

LimitContentWidth.propTypes = {};
LimitContentWidth.defaultProps = {};
