import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

Metadata.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    metadescription: PropTypes.string,
    metakeywords: PropTypes.string,
    ogTitle: PropTypes.string,
    ogType: PropTypes.string,
    ogImage: PropTypes.string,
  }),
  defaultTitle: PropTypes.string,
  canonicalSiteUrl: PropTypes.string.isRequired,
  path: PropTypes.string,
};

Metadata.defaultProps = {
  defaultTitle: null,
  path: null,
};

export function Metadata({ data, opengraphData, defaultTitle, canonicalSiteUrl, path }) {
  const { title, metadescription, metakeywords } = data ? data : { title: defaultTitle };

  const { title: ogTitle, description: ogDescription, type: ogType, image: ogImage } = opengraphData || {};

  const url = canonicalSiteUrl + path;
  const showOpenGraphTags = ogType || ogTitle || ogImage;

  return (
    <Helmet>
      {(title || defaultTitle) && <title>{title || defaultTitle}</title>}
      {metadescription && <meta name="description" content={metadescription} />}
      {metakeywords && <meta name="keywords" content={metakeywords} />}
      {(ogTitle || title || defaultTitle) && <meta property="og:title" content={ogTitle || title || defaultTitle} />}
      {(ogDescription || metadescription) && <meta property="og:description" content={ogDescription || metadescription} />}
      {ogImage && [
        <meta
          key="og_image_url"
          property="og:image:url"
          content={canonicalSiteUrl + ogImage.childImageSharp.gatsbyImageData.images.fallback.src}
        />,
        <meta key="og_image_height" property="og:image:height" content={ogImage.childImageSharp.gatsbyImageData.height} />,
        <meta key="og_image_width" property="og:image:width" content={ogImage.childImageSharp.gatsbyImageData.width} />,
      ]}
      {showOpenGraphTags && [
        <meta key="og_type" property="og:type" content={ogType || 'website'} />,
        <meta key="og_url" property="og:url" content={url} />,
      ]}
    </Helmet>
  );
}
