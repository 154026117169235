import React from 'react';
import PropTypes from 'prop-types';
import { LimitContentWidth } from './LimitContentWidth';
import { PageTitle } from './PageTitle';
import InnerHTML from 'dangerously-set-html-content';
import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';

import * as styles from './SimpleContentPage.module.css';
import { Icon } from './Icon';

export const SimpleContentPage = (props) => {
  const { title, pageID, teaser, text, image, icon, cmsUrl } = props;

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          cookieDeclarationScript
        }
      }
    }
  `);

  const cookieScript = data.site.siteMetadata.cookieDeclarationScript;

  const modifiedcontent =
    text && text.split('src="/').join(`src="${cmsUrl}`).split('{{cookiescript}}').join(cookieScript);
  return (
    <LimitContentWidth>
      <PageTitle>
        {icon && <Icon icon={icon} />}
        {title}
      </PageTitle>

      {image && image.publicURL && <img src={image.publicURL} style={{ width: '100%', marginBottom: 6 }} />}
      {teaser && <div className={`${styles.teaser} ${pageID || ''}`}>{teaser}</div>}
      {text && (
        <div className={clsx(styles.content, pageID)}>
          <InnerHTML html={modifiedcontent} />
        </div>
      )}
    </LimitContentWidth>
  );
};

SimpleContentPage.propTypes = {
  title: PropTypes.string,
  pageID: PropTypes.string,
  teaser: PropTypes.string,
  text: PropTypes.string,
};
SimpleContentPage.defaultProps = {};
